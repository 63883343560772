.launch-bg {
  position: relative;
  background-image: url(/public/assets/img/launch.jpg);
  background-size: cover;
}

.launch-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 0.9;
  background-color: rgba(51, 25, 10, 0.445);
  z-index: 10;
  backdrop-filter: blur(5px);
}

.launch-bg .launch-page {
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
}
