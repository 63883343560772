@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.landing-bg-overlay-gradient {
  background: linear-gradient(
    0deg,
    rgba(12, 10, 11, 1) 0%,
    rgba(12, 10, 11, 0) 100%
  );
}

.section-title::before {
  content: "";
  position: absolute;
  display: block;
  width: 80px; /* Adjust the width as needed */
  height: 3px; /* Adjust the height as needed */
  background-color: #dd7538; /* Set the background color */
  left: 0%;
  bottom: -10px;
}

.educator-bg-color {
  background: rgb(2, 6, 23);
  background: linear-gradient(
    0deg,
    rgba(2, 6, 23, 1) 0%,
    rgba(12, 10, 11, 1) 100%
  );
}

.educator-card-bg-color {
  background: rgb(42, 18, 4);
  background: linear-gradient(
    35deg,
    rgb(17, 6, 1) 0%,
    rgba(122, 47, 3, 1) 57%,
    rgba(255, 94, 0, 1) 96%
  );
  transition: all 0.3s ease-in-out;
}

.educator-card-bg-color:hover {
  transform: translateY(-5px) scale(1.01);
}

.educator-card-bg-color img {
  transition: all 0.3s ease-in-out;
}

.educator-card-bg-color:hover img {
  transform: translateY(-2px) scale(1.05);
}

.educator-card-bg-color dd {
  color: #ffdcd1;
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  font-size: 1.7rem;
}
/* 
.category-bg-color {
  background-color: rgb(15 23 42);
  background: linear-gradient(
    180deg,
    rgba(2, 6, 23, 1) 0%,
    rgba(15, 23, 42, 1) 100%
  );
} */

.judges-bg-color {
  background-color: rgb(15 23 42);
  background: linear-gradient(
    0deg,
    rgba(2, 6, 23, 1) 0%,
    rgba(15, 23, 42, 1) 100%
  );
}

.apply-field {
  font-size: 16px;
  background: #ffffff65;
  border: 1px solid #cacaca;
  color: #ffffff;
  backdrop-filter: blur(5px);
  border-radius: 5px;
  width: 100%;
  outline: none;
  padding: 18.5px 14px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
}

.apply-field option {
  color: #000000;
}

.apply-field-tel .form-control {
  background: #ffffff65 !important;
  border: 1px solid #cacaca;
  backdrop-filter: blur(5px);
  color: #ffffff;
  border-radius: 5px;
  width: 100%;
  outline: none;
  padding: 18.5px 14px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
}

.apply-form .react-tel-input .form-control:focus {
  border-color: #dd7538;
  box-shadow: 0 0 0 1px #dd7538;
}

#categories {
  position: relative;
  background-image: url(/public/assets/img/categories-bg.jpg);
  background-size: cover;
}

#categories::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 0.9;
  background-color: rgb(9, 14, 24);
  z-index: 10;
  backdrop-filter: blur(5px);
}

.apply-bg-color {
  background-color: rgb(15 23 42);
}

.apply-bg-color:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 0.65;
  background-color: rgb(9, 14, 24);
  z-index: 10;
  backdrop-filter: blur(5px);
}

.squrecard {
  position: relative;
  z-index: 20;
  backdrop-filter: blur(5px);
}

.squrecard:hover .icon {
  color: #dd7538;
}

.parallax-bg-judge {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-image: url(/public/assets/img/judges-bg.jpg);
}

.parallax-bg-judge::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 0.9;
  background-color: rgb(9, 14, 24);
  z-index: 10;
}

.judge-swiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.judge-swiper .swiper-pagination {
  bottom: 20%;
  color: #ffffff;
}

.judge-swiper .swiper-pagination-current {
  color: #ff8c4a;
}

.contactus-bg {
  position: relative;
  background-image: url(/public/assets/img/contact-bg.png);
  background-size: cover;
  background-position: 0 65%;
}

.contactus-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 0.85;
  background-color: rgb(15 23 42);
  z-index: 10;
}

.social-media-icons {
  position: fixed;
  bottom: 50px; /* Adjust the position as needed */
  right: 30px; /* Adjust the position as needed */
  display: flex;
  flex-direction: column; /* Icons stack vertically */
  gap: 10px; /* Adjust the gap between icons */
  z-index: 10000;
}

.social-media-icons a {
  text-decoration: none;
  color: #333; /* Change icon color as needed */
}

.social-media-icons img {
  width: 45px; /* Adjust icon size as needed */
  height: 45px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  z-index: 20000;
  width: 100%;
  background-color: rgba(15, 23, 42, 0.7);
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .section-title::before {
    display: none;
  }

  .he-cat-btn,
  .sc-cat-btn {
    font-size: 14px;
  }

  .he-cat-btn.selected,
  .sc-cat-btn.selected {
    font-size: 14.5px !important;
  }
}

.images-bullet {
  background-color: #ff8c4a !important;
  box-shadow: 0 0 3px 1px #6b6b6b !important;
}

.category-buttons {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.he-cat-btn,
.sc-cat-btn {
  width: 50%;
  padding: 20px;
  background: #d1ad9365;
  border: 3px solid #dd7538;
  color: #ffffff;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.he-cat-btn:hover,
.sc-cat-btn:hover {
  font-size: 16.3px;
  background: #dd743857;
}

.he-cat-btn.selected,
.sc-cat-btn.selected {
  background: #dd7438d7;
  border: 3.5px solid #cacaca;
  font-weight: 500;
  font-size: 16.5px;
  transition: all 0.5s ease-in-out;
}

.fancy {
  --offset: 3px;
  position: relative;
  overflow: hidden;
}

/* Conic gradient */
.fancy::before {
  content: "";
  background: conic-gradient(transparent 270deg, white, transparent);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1;
  width: 100%;
  animation: rotate 2s linear infinite;
}

/* Overlay */
.fancy::after {
  content: "";
  background: inherit;
  border-radius: inherit;
  position: absolute;
  inset: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));
}

.fancy span {
  color: white;
  position: absolute;
  inset: 0;
  z-index: 10;
}

/* @media (max-width: 377px) {
  .fancy span {
    width: 150px;
  }
} */

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
  }

  to {
    transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
  }
}
